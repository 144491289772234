<template>
  <TopBar />
  <NavBar />
  <DefaultBanner
    title="Contact Us"
    :class="['lg:contact-us-banner-bg', 'contact-us-mobile-bg']"
  />
  <div class="w-100 lg:pt-20 relative overflow-hidden">
    <div
      class="
        absolute
        z-0
        left-0
        right-0
        top-0
        bottom-0
        flex flex-row
        justify-between
        items-center
      "
    >
      <img
        class="lg:w-auto w-full absolute left-0 top-0 hidden lg:block"
        src="/images/map-full.png"
      />
      <img
        class="lg:w-auto w-1/2 absolute left-0 top-0 lg:hidden"
        src="/images/map-right.png"
      />
    </div>
    <div class="container mx-auto lg:px-3 px-5 py-6 z-10 relative lg:mb-12">
      <div class="flex lg:flex-row flex-col-reverse w-full">
        <div class="lg:w-1/2 lg:pr-12 lg:mt-0 mt-12">
          <div class="bg-gray-lighter p-6">
            <h2 class="text-xl font-bold text-black-light mt-4">
              Contact Info
            </h2>
            <p class="mt-4 text-black-lighter mb-12">
              FairPY has a strong and committed sales staff with years of
              experience satisfying our customers’ needs.
            </p>

            <ContactDetail
              image="/images/icons/contact-address.png"
              title="Address"
              content="4425 W Airport Fwy, Ste 131, Irving,Tx, 75062, USA"
            ></ContactDetail>

            <ContactDetail
              image="/images/icons/contact-phone.png"
              title="Phone"
              content="817-357-8462"
            ></ContactDetail>

            <ContactDetail
              image="/images/icons/contact-email.png"
              title="Email"
              content="admin@fairpy.com"
            ></ContactDetail>

            <ContactDetail image="/images/icons/contact-chat.png" title="Chat">
              <p class="text-black-lighter">
                Star a Live Chat <span class="text-blue"> > </span>
              </p>
            </ContactDetail>

            <div class="flex mt-16 mb-8">
              <div class="mr-8">
                <a href="https://www.facebook.com/gofairpy/" target="_blank"
                  ><img src="/images/icons/contact-fb.png"
                /></a>
              </div>
              <div class="mr-8">
                <a href="https://twitter.com/gofairpy" target="_blank"
                  ><img src="/images/icons/contact-twitter.png"
                /></a>
              </div>
              <div class="mr-8">
                <a href="https://www.instagram.com/gofairpy/" target="_blank"
                  ><img src="/images/icons/contact-insta.png"
                /></a>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:w-1/2 pt-2 p-0">
          <h1 class="text-4xl text-black-light font-display font-extrabold">
            Get in touch
          </h1>
          <div class="border-t-2 border-blue w-28 mt-8"></div>
          <p class="text-black-lighter mt-10">
            Feel free to browse our massive inventory online. If you got any
            questions, please do not hesitate to send us a message. We reply
            within 24 hours !
          </p>
          <div class="flex flex-col mt-8">
            <div class="text-red-500 text-sm mb-6" v-if="errors.general">
              {{ errors.general }}
            </div>
            <div class="mb-6">
              <input
                v-model="name"
                class="border p-4 w-full"
                :class="errors.name ? 'border-red-500' : ''"
                placeholder="Your Name"
              />
              <div class="text-red-500 text-sm mt-2" v-if="errors.name">
                {{ errors.name }}
              </div>
            </div>
            <div class="flex lg:flex-row flex-col mb-6">
              <div class="flex-grow lg:mr-3 mb-6 lg:mb-0">
                <input
                  v-model="phone"
                  class="border p-4 w-full"
                  :class="errors.phone ? 'border-red-500' : ''"
                  placeholder="Your Phone"
                />
                <div class="text-red-500 text-sm mt-2" v-if="errors.phone">
                  {{ errors.phone }}
                </div>
              </div>
              <div class="flex-grow lg:ml-3">
                <input
                  v-model="email"
                  :class="errors.email ? 'border-red-500' : ''"
                  class="border p-4 w-full"
                  placeholder="Your Email"
                />
                <div class="text-red-500 text-sm mt-2" v-if="errors.email">
                  {{ errors.email }}
                </div>
              </div>
            </div>
            <div class="mb-8">
              <textarea
                rows="7"
                v-model="message"
                :class="errors.message ? 'border-red-500' : ''"
                class="border p-4 w-full"
                placeholder="Your Message"
              ></textarea>
              <div class="text-red-500 text-sm mt-2" v-if="errors.message">
                {{ errors.message }}
              </div>
            </div>

            <div class="flex justify-start">
              <button
                @click.prevent="contactUs"
                type="button"
                class="bg-blue text-white h-11 text-sm uppercase px-8"
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 relative overflow-hidden mb-10 mt-10 pb-10">
    <div
      class="
        absolute
        z-0
        left-0
        right-0
        top-0
        bottom-0
        flex flex-row
        justify-between
        items-center
      "
    >
      <img
        class="w-auto absolute lg:left-0 lg:top-4 right-0 -bottom-10 block"
        src="/images/dots.png"
      />
    </div>
    <div class="flex lg:flex-row flex-col-reverse relative items-stretch">
      <div
        class="
          lg:flex-grow
          relative
          flex
          lg:pt-6 lg:pb-6 lg:pl-10 lg:pr-0
          p-3
          -mt-16
          lg:mt-0
          z-40
        "
      >
        <GMapMap
          :center="mapCenter"
          :zoom="14"
          :options="mapOptions"
          class="w-full lg:h-full h-96"
        >
          <GMapMarker
            :icon="markerIcon"
            :position="marker.position"
            :clickable="true"
            @click="showInfoWindow = true"
          >
            <GMapInfoWindow
              :closeclick="true"
              @closeclick="showInfoWindow = false"
              :opened="showInfoWindow"
              :options="{
                pixelOffset: {
                  width: -24,
                  height: 0,
                },
              }"
            >
              <div>4425 W Airport Fwy, Ste 131, Irving, Tx, 75062, USA</div>
            </GMapInfoWindow>
          </GMapMarker>
        </GMapMap>
      </div>
      <div class="lg:w-1/3 z-20 relative">
        <div class="special-shadow relative w-full">
          <img class="w-full" src="/images/office-image.png" />
        </div>
      </div>
    </div>
  </div>
  <Footer />
  <Modal
    :showModalBg="false"
    bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-5 min-h-screen lg:min-h-0"
    v-show="isThankYouModalVisible"
    @close="closeModal"
  >
    <template v-slot:header>
      <div></div>
    </template>

    <template v-slot:body>
      <div class="flex justify-center relative w-full">
        <div
          class="
            w-full
            flex flex-col
            justify-center
            items-center
            text-center
            lg:px-12
            py-12
            px-6
            relative
          "
        >
          <div class="lg:w-2/3 w-10/12 flex flex-col items-center mt-8">
            <img class="lg:w-36" src="/images/thankyou-modal-icon.png" />
          </div>
          <h1
            class="
              text-3xl
              font-display
              mt-8
              mb-6
              text-center
              lg:px-12 lg:mx-12
              px-4
              mx-4
            "
          >
            Thank You
          </h1>
          <p class="text-sm text-gray-medium lg:px-4 px-0 mb-6">
            We will get back to you on this shortly.
          </p>
          <button
            @click.prevent="closeModal"
            class="bg-blue uppercase text-white p-3 px-6 block"
          >
            Continue
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import ContactDetail from "../components/ContactDetail.vue";
import DefaultBanner from "../components/DefaultBanner.vue";
import Footer from "../components/Footer.vue";
import Modal from "../components/Modal.vue";
import NavBar from "../components/NavBar.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "Home",
  components: {
    TopBar,
    NavBar,
    DefaultBanner,
    Footer,
    ContactDetail,
    Modal,
  },
  data() {
    return {
      isThankYouModalVisible: false,
      formAttempt: 0,
      name: "",
      phone: "",
      errors: {},
      email: "",
      message: "",
      showInfoWindow: false,
      markerIcon: {
        url: "/images/icons/map-marker.png",
        scaledSize: { width: 98, height: 77 },
        anchor: { x: 24, y: 77 },
      },
      marker: {
        position: {
          lat: 45.27089433990617,
          lng: -93.00450073670775,
        },
      },
      mapCenter: {
        lat: 45.27089433990617,
        lng: -93.00450073670775,
      },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#444444",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              {
                color: "#f2f2f2",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#46bcec",
              },
              {
                visibility: "on",
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    email: function () {
      if (this.formAttempt > 0) this.validateForm();
    },
    phone: function () {
      if (this.formAttempt > 0) this.validateForm();
    },
    name: function () {
      if (this.formAttempt > 0) this.validateForm();
    },
    message: function () {
      if (this.formAttempt > 0) this.validateForm();
    },
  },
  methods: {
    validateForm() {
      this.formAttempt++;
      this.errors = {};
      let valid = true;
      if (!this.name.trim()) {
        this.errors["name"] = "Name is required";
        valid = false;
      }
      if (!this.email || !this.validateEmail(this.email)) {
        valid = false;
        this.errors["email"] = "A valid Email is required";
      }

      if (!this.phone || !this.validatePhone(this.phone)) {
        valid = false;
        this.errors["phone"] = "A valid Phone is required";
      }
      if (!this.message.trim()) {
        this.errors["message"] = "Message is required";
        valid = false;
      }

      return valid;
    },

    validateEmail(email) {
      // eslint-disable-next-line
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
        email
      );
    },
    validatePhone(phone) {
      // eslint-disable-next-line
      return /^\d{8,15}$/.test(phone);
    },

    showThankYouModal() {
      this.isThankYouModalVisible = true;
      document.body.classList.add("overflow-hidden");
      // this.updateBidToServer();
    },
    closeModal() {
      this.isThankYouModalVisible = false;
      document.body.classList.remove("overflow-hidden");
    },

    contactUs() {
      if (!this.validateForm()) {
        return;
      }
      const params = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message,
      };

      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("auth/contact", params).then(
        (data) => {
          console.log(data);
          this.$store.dispatch("setIsLoading", false);
          this.showThankYouModal();
          this.name = "";
          this.email = "";
          this.phone = "";
          this.message = "";
          this.errors = {};
          this.formAttempt = 0;
        },
        (error) => {
          this.$store.dispatch("setIsLoading", false);
          if (error.response) {
            console.log(error.response);
            if (error.response.data.message) {
              this.errors["general"] = error.response.data.message;
            }
            for (const key in error.response.data.errors) {
              this.errors[key] = error.response.data.errors[key].join(", ");
            }
          } else {
            console.log(error.request);
            this.errors["general"] = "Sorry! Something went wrong!";
          }
        }
      );
    },
  },
};
</script>

<style></style>